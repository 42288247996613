export default {
  spatialMarker: '空间标记',
  searchPlaceholder: '搜索空间标记点名称',
  autoBind: '自动绑定巡检照片',
  createGroup: '创建分组',
  createChild: '创建子分组',
  createSpacialMarker: '创建空间标记',
  groupType: '分组类型',
  groupName: '分组名称',
  childGroupName: '子分组名称',
  groupTypePlaceholder: '请选择分组类型',
  groupNamePlaceholder: '请输入分组名称',
  groupNameRequired: '分组名称为必填项',
  groupNameDuplicate: '分组名称不能重复',
  childGroupNameRequired: '请输入子分组名称',
  markerColorPlaceholder: '请选择标记点的颜色',
  markerColor: '选择标记点的颜色',
  editGroup: '编辑分组',
  deleteSpatialMarker: '确定删除该空间标记点吗？',
  deleteGroup: '删除分组会同时删除该分组下的内容，是否继续？',
  autoBindTitle: '选择自动绑定照片的范围',
  distanceLabel: '设定绑定照片的距离（单位:cm）',
  distancePlaceholder: '请输入10~200的范围',
  unBind: '未绑定',
  bind: '已绑定',
  startCount: '开始计算',
  batchRemove: '移出照片',
  confirmRemove: '确定移出',
  selecteAll: '全选',
  removeSuccess: '移除成功',
  removeSingle: '确定要移除当前图片吗？',
  removeSelect: '批量移出会将你勾选的照片全部移出该空间标记点，是否继续？',
  editSuccess: '修改成功',
  addSuccess: '新增成功',
  addSpaceMarker: '添加空间标记',
  editSpaceMarker: '编辑空间标记',
  markerNumber: '编号',
  numberPlaceholder: '请输入编号 例：1#',
  markerName: '部件/构件等结构名称',
  markerPosition: '空间标记位置',
  picked: '已选择',
  unPicked: '未选择',
  choose: '选择',
  reChoose: '重选',
  imageManagement: '图片管理',
  historyList: '历史巡检图片列表',
  compare: '照片对比',
  disease: '病害',
  nameView: '标记名称预览',
  partNamePlaceholder: '请输入名称',
  markerPlaceholder: '请在模型中标记点位',
  startCompare: '开始对比',
  hide: '隐藏',
  open: '展开',
  rePickMarkerTips:
    '重选择空间标记，确定要删除吗？',
  onlyOneInspection: '每次巡检只能选择一张图片进行对比',
  onlyFourInspection: '每次对比最多支持四张图片',
  structure: '部件构件',
  other: '普通标记',
  computeSuccess: '自动绑定巡检照片成功',
  moveToGroup: '绑定到空间标记点',
  moveToTitle: '选择要移动到哪些标记点',
  moveSuccess: '移动成功',
  moveOutSuccess: '移除成功',
  noInspectionData: '暂无巡检数据',
  hideMarker: '隐藏空间点',
  showMarker: '显示空间点',
  editMarkerName: '编辑标记名称',
  unbindImg: '待绑定照片',
  unbindImgPlaceholder: '待绑定部件构件照片数量',
  addImg: '添加照片',
  showAllImage: '显示当前巡检照片',
  currentInspection: '当前巡检',
  addNewMarker: '新增标记',
  addImage: '添加图片',
  bindImage: '绑定图片',
  batchBindImgTips:
    '批量绑定会将你勾选的图片全部绑定到该空间标记点，是否继续？',
  rectSelect: '框选',
  disableRectSelect: '取消框选',
  rectTips: '请通过鼠标左键进行框选',
  allImgs: '全部照片',
  allGroups: '全部分组'
}

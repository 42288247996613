
export default {
  all: 'All',
  enterProject: 'Open Project',
  createProject: 'New project',
  projectInfo: 'Project info',
  projectInfoDes: 'Basic info setting',
  uploadModel: 'Upload model',
  uploadModelDes: 'Upload project 3D model',
  createTime: 'Date',
  projectName: 'Project name',
  projectNameDes: 'Please name the project, 50 letters maximum.',
  projectType: 'Type',
  auth: 'Permission',
  projectUser: 'Members',
  projectIcon: 'Page cover',
  projectDetail: 'Profile',
  projectDetailDes: 'Please fill in the project profile',
  choosed: '[0] person selected.',
  chooseMember: 'Select member',
  teamMember: 'All members',
  projectMember: 'Select by member.',
  selectedUser: '[0] member selected.',
  clearAll: 'Clear All',
  selecteAll: 'Select All',
  removeUser: 'Remove Member',
  searchUser: 'Search',
  addSuccess: 'Project created successfully.',
  teamUserError: 'The user does not exist.',
  deleteSuccess: 'Project deleted successfully.',
  backAlertTitle: 'Confirm return to Home page',

  backHome: 'Home page',
  editing: 'Editing right now,sure to jump to the home page?',
  chooseFile: 'Select file',
  rechooseFile: 'Reupload model',
  startUpload: 'Start uploading',
  continueUpload: 'Continue uploading',
  modelUploadExmaple: 'Format example',
  modelUploadExampleTips: 'Select the root folder include .json file.',
  cancelUpload: 'Cancel uploading',
  pauseUpload: 'Pause uploading',
  uploadSuccess: 'uploaded successfully',
  uploadErrorTips: 'Upload failed, please select the original file path and click to continue uploading',
  noTilesetJSON: 'There is no tileset.json file you selected.',
  home: 'Home page',
  teamMangement: 'Team Mangement',
  searchTeam: 'Search',
  saveModelCatchLoading: 'Model parsing in progress...',
  noProject: 'No data available',
  uploadImg: 'Upload Cover',
  reUpload: 'Update cover',
  uploadFailed: 'Upload failed',
  modelError: 'Model file error/not uploaded',
  userUploading: 'User {name} is uploading.',
  projectNotExist: 'Project does not exist',
  selectNoEmpty: 'Please select a project',
  selectedUserNoEmpty: 'Please select project members',
  backAlertContent: 'Editing right now,sure to jump to the home page?',
  owner: 'Owner',
  me: 'Me',
  deleteProject: 'Delete Project',
  deleteProjectTips: `{name}'s project will be permanently deleted, please enter the password to confirm. `,
  projectTypeName: 'Project type name',
  projectTypeNamePlaceholder: 'Please enter project type name',
  projectTypeIcon: 'Project type icon',
  createProjectType: 'Create project type',
  projectTypeIconPlaceholder: 'Please select project type icon',
  projectTypeList: 'Deleting failed, please delete the items under the current item type first, a total of [0] items',
  projectConfig: 'Project configuration',
  diseaseFieldConfig: 'Disease field configuration ',
  diseaseTypeFieldConfig: 'Disease type field configuration',
  addField: 'New field',
  importForOtherProject: 'Import from other projects',
  diseaseFieldName: 'Disease field name',
  diseaseFieldDeleteText: 'Are you sure to delete the disease field?',
  addDiseaseField: 'New disease field',
  editDiseaseField: 'Edit disease field',
  diseaseFieldPlaceholder: 'Please enter a field name',
  importDiseaseField: 'Import disease field',
  selectedDiseaseType: 'The disease type has been selected',
  selectedDiseaseField: 'The disease field has been selected',
  diseaseFieldDeleteConfirmText: 'This disease field is already in use. Do you want to delete it?',
  diseaseTypeName: 'Disease type name',
  diseaseMarkerColor: 'Disease marker color',
  diseaseVisible: 'Show or not',
  diseaseVisibleTips: 'Whether to display the disease type when a new disease is added.',
  diseaseTypeColorPlaceholder: 'Please select a disease marker color',
  diseaseTypeNamePlaceholder: 'Please enter a disease type name',
  newType: 'New type',
  diseaseTypeDeleteText: 'Are you sure to delete the disease type?',
  importDiseaseType: 'Imported disease type',
  widgetType: 'Form type',
  diseaseFieldVisibleTips: 'Whether to display the disease field when a disease is marked.',
  diseaseFormPreview: 'Preview effect',
  diseaseFieldPreviewTitle: 'Disease field - Preview',
  selectOptionError: 'Please add drop-down options',
  currentProjectDelete: 'The current project has been deleted',
  uploadModelRepeat:' You are uploading a model, do not repeat ',
  uploadModelSelectText:' The model file you selected is different from the last one. Do you want to overwrite the new model? '

}

// 用户管理、登录相关API
import request from '../axios.js'

export default {
  logout() {
    return request({
      url: '/web/logout',
      method: 'post'
    })
  },
  getUserInfo(path) {
    return request({
      url: `/web/info/${path}`,
      method: 'GET'
    })
  },
  getUserPage(data) {
    return request({
      url: '/user/page',
      method: 'post',
      data
    })
  },

  getRoleList() {
    return request({
      url: '/role/list',
      method: 'get'
    })
  },

  userEdit(data) {
    return request({
      url: '/user/edit',
      method: 'post',
      data
    })
  },
  userDelete(data) {
    return request({
      url: `/user/del/batch`,
      method: 'post',
      data
    })
  },

  getUserByID(id) {
    return request({
      url: `user/${id}`,
      method: 'get'
    })
  },
  // 用户注册发送短信验证码
  sendSMS(params) {
    return request({
      url: '/web/sms',
      method: 'POST',
      params
    })
  },
  // 用户注册
  userRegister(data) {
    return request({
      url: `/web/register`,
      method: 'POST',
      data
    })
  },
  // 用户手机验证码校验
  checkSMS(params) {
    return request({
      url: '/web/check-code',
      method: 'POST',
      params
    })
  },
  // 校验手机号是否已存在
  checkPhone(params) {
    return request({
      url: '/web/check-phone',
      method: 'POST',
      params
    })
  },
  // 获取岗位字典
  getJobs() {
    return request({
      url: '/dictionary-data/register-post',
      method: 'GET'
    })
  },
  loginVerifyCode() {
    return request({
      url: '/limit/check',
      method: 'POST'
    })
  },
  login(data) {
    return request({
      url: '/web/login',
      method: 'POST',
      data
    })
  },
  // 重新设置密码
  retrievePassword(data) {
    return request({
      url: '/web/retrieve',
      method: 'POST',
      data
    })
  },
  // 校验用户密码
  checkPassword(params) {
    return request({
      url: '/user-client/check-password',
      method: 'GET',
      params
    })
  },
  // #region 个人中心相关
  async updateUserInfo(data) {
    const res = request({
      url: '/user-client',
      method: 'PUT',
      data
    })
    return res
  },

  async updatePhone(params) {
    const res = request({
      url: '/user-client/phone',
      method: 'PUT',
      params
    })
    return res
  },

  async getUserDetailById() {
    const res = request({
      url: `/user-client/details`,
      method: 'GET'
    })
    return res
  },
  //  修改密码
  async updatePSD(data) {
    const res = request({
      url: '/user-client/password',
      method: 'PUT',
      data
    })
    return res
  },
  // 修改头像
  async updateAvatar(headImgUrl) {
    const res = request({
      url: '/user-client/head-portrait',
      method: 'PUT',
      params: { headImgUrl }
    })
    return res
  },
  // 用户离线
  userOffline() {
    return request({
      url: '/user-client/temp-offline',
      method: 'POST'
    })
  },
  // 用户上线
  userOnline() {
    return request({
      url: '/user-client/wake-up',
      method: 'POST'
    })
  }
  // #endregion
}

export default {
  logo: '通途检',
  cancel: '取消',
  confirm: '确定',
  error: '错误',
  fail: '失败',
  warn: '警告',
  success: '成功',
  delete: '删除',
  loading: '加载中...',
  back: '返回',
  next: '下一步',
  pre: '上一步',
  action: '操作',
  edit: '编辑',
  add: '新增',
  import: '导入',
  done: '完成',
  submit: '提交',
  inputEmptyTips: '输入值不能为空',
  maxInputLabelsTips: '输入字符不能超过50个字',
  placeholder: '请输入',
  save: '保存',
  handleSuccess: '操作成功',
  fullScreen: '全屏',
  remove: '移除',
  move: '移动',
  passwordValid: '密码验证',
  passwordPlaceholder: '请输入密码',
  passwordError: '密码错误',
  deleteSuccess: '删除成功',
  selected: '选中',
  currentUser: '当前用户 ',
  backToHome: '主页',
  addSuccess: '添加成功',
  dataEmpty: '暂无数据',
  pageNotFound: '你访问的页面不存在',
  measure: '直线测量',
  measureTri: '三角测量',
  reset: '重置',
  activation: '软件激活',
  know: '知道了',
  hint: '提示',
  systemHint: '系统提示',
  confirmDelete: '确认删除',
  close: '关闭',
  piece: '张',
  me: '我',
  noData: '暂无数据',
  image: '照片',
  check: '筛查',
  allClear: '全部清除',
  hour: '小时',
  day: '天',
  minutes: '分钟',
  imageChecked: '图片已筛查',
  noModelTips: '当前项目暂无模型!',
  imagePoint: '照片点',
  inspectionPoint: '巡检点',
  inspection: '巡检',
  personalCenter: '个人中心',
  bindSuccess: '绑定成功',
  noBindData: '巡检点暂未匹配到照片',
  removeTeam: '您已被移出{team}团队',
  templeSave: '暂存',
  logoutTips: '登出成功',
  confirmLogin: '确认登录',
  confirmLoginTips: '你输入的账号已有用户登录,确认登录对方将被挤掉强制下线。是否继续?',
  cancelLogin: '取消登录',
  otherLogin: '其他人正在登录此账户!',
  otherLoginSuccess: '你已经被其他用户挤下线!',
  saving: '保存中...',
  prePage: '上一张',
  nextPage: '下一张',
  feedbackNotEmpty: '反馈内容不能为空',
  check_1: '已筛查',
  check_0: '未筛查',
  check_2: '存疑',
  check_1_tips: '已筛查，点击切换为未筛查',
  check_0_tips: '未筛查，点击切换为已筛查',
  check_2_tips: '存疑',
  index: '序号',
  fieldName: '字段名称',
  dragsort: '拖动排序',
  systemNotic: '平台公告',
  known: '我已知晓',
  lastRefreshTime: '上次刷新时间',
  ImpeachPhoto: '存疑照片',
  yes: '是',
  no: '否',
  creator: '创建人',
  system: '系统',
  uploadNetError: '网络错误，上传失败',
  uploadServerError: '网络异常，请刷新页面重试',
  sort: '排序',
  input: '输入框',
  select: '单选下拉框',
  multiplySelect: '多选下拉框',
  option: '选项',
  default: '默认',
  placeholderSelect: '请选择',
  size: '尺寸',
  number: '编号',
  show: '显示',
  backHome3D: '回到默认视角',
  backHome: '回到首页',
  batchDelete: '批量删除',
  reDraw: '重绘',
  search: '查询',
  isUploading: '正在上传',
  imageDownload: '图片下载中',
  cancelDownload: '取消下载',
  downloadComplete: '下载完成',
  uploadSuccess: '上传成功',
  serviceError: '服务异常',
  deleteLoading: '删除中,请稍后...'
}

export default {
  historyDisease: '病害演变',
  mouseTips: '提示：鼠标左键拖动图像；滚轮放大/缩小；按住Ctrl+左键或者鼠标右键可单独操作图像，调整图像的中心点。',
  centerMarker:'中心点',
  disease:'病害',
  diseaseList:'病害列表',
  diseaseSize:'病害尺寸',
  diseaseLevel:'病害标度',
  diseaseDetail: '病害详情',
  diseaseDes:'病害描述',
  markerTime:'标记人/时间',
  spacialMarker: '空间标记',
  bind:'照片已绑定',
  reDrawDisease : '重绘标记'
}

import { createWebHistory, createRouter } from 'vue-router'
import { userInfoStore } from '@/store/userInfo'
import { routerAndPremission } from '@/store/routerAndPremission'
import { teamStore } from '@/store/team'
import {
    TokenStorage
} from '@/utils/storageUtil'
import { UserMangerApi } from '@/api'
import { i18n } from '@/utils/i18n'
const { tm, t } = i18n.global
    // static rotute
const routes = [{
        path: '/',
        redirect: '/home',
        name: 'Root',
        component: () =>
            import ('@/views/Layout.vue'),
        children: []
    },
    {
        path: '/login',
        name: 'LoginPage',
        component: () =>
            import ('@/views/Login/Login.vue'),
        meta: {
            requiresAuth: false
        },
        children: [{
                path: '/login/',
                name: 'Login',
                component: () =>
                    import ('@/views/Login/components/login.vue')
            },
            {
                path: '/login/register',
                name: 'Register',
                component: () =>
                    import ('@/views/Login/components/register.vue')
            },
            {
                path: '/login/forget',
                name: 'Forget',
                component: () =>
                    import ('@/views/Login/components/forget.vue')
            }
        ]
    },
    {
        path: '/404',
        name: '404',
        component: () =>
            import ('@/views/404/Index.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/compare',
        name: 'Compare',
        component: () =>
            import ('@/components/CompareImageEditor/Index.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/invite',
        name: 'Invite',
        component: () =>
            import ('@/views/InviteJoin/Index.vue'),
        meta: {
            requiresAuth: false
        }
    },
    // 测试用
    {
        path: '/recycleBin',
        name: 'RecycleBin',
        component: () =>
            import ('@/views/RecycleBin/recycleBin.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/photos',
        name: 'Photos',
        component: () =>
            import ('@/views/Inspection/components/InsImgsManage/index.vue'),
        meta: {
            requiresAuth: true
        }
    },
    // {
    //   path: '/projectConfig',
    //   name: 'ProjectConfig',
    //   component: () => import('@/views/ProjectConfig/Index.vue'),
    //   redirect: '/projectConfig/field',
    //   children: [{
    //     path: '/projectConfig/field',
    //     name: 'ProjectConfigField',
    //     component: () => import('@/views/ProjectConfig/Field.vue')
    //   }, {
    //     path: '/projectConfig/type',
    //     name: 'ProjectConfigType',
    //     component: () => import('@/views/ProjectConfig/Type.vue')
    //   }]
    // },
    {
        path: '/:catchAll(.*)', // /*
        redirect: '/404'
    }
]

const router = createRouter({
    history: createWebHistory('/'), // 路由类型
    routes // short for `routes: routes`
})

const loadRoute = (routes) => {
    if (!routes || !Array.isArray(routes)) return
    routes.forEach((route) => {
        // 排除后台系统路由
        if (route.isBackground) return
        const options = {
            path: route.menuPath,
            name: route.pathName,
            component: () =>
                import (`@/views/${route.componentPath}`),
            meta: {
                requiresAuth: (route.requiresAuth === undefined || route.requiresAuth === null) ? true : route.requiresAuth,
                title: route.menuName,
                icon: route.menuIcon
            },
            children: []
        }
        if (route.childList && route.childList.length > 0) {
            options.redirect = route.childList[0].menuPath
            route.childList.forEach((child) => {
                const _options = {
                    path: child.menuPath,
                    name: child.pathName,
                    component: () =>
                        import (`@/views/${child.componentPath}`),
                    meta: {
                        requiresAuth: true,
                        title: child.menuName,
                        icon: child.menuIcon
                    },
                    children: []
                }
                if (child.childList && child.childList.length > 0) {
                    child.childList.forEach((_child) => {
                        _options.redirect = child.childList[0].menuPath
                        _options.children.push({
                            path: _child.menuPath,
                            name: _child.pathName,
                            component: () =>
                                import (`@/views/${_child.componentPath}`),
                            meta: {
                                requiresAuth: true,
                                title: _child.menuName,
                                icon: _child.menuIcon
                            }
                        })
                    })
                }
                options.children.push(_options)
            })
        }
        router.addRoute('Root', options)
    })
}
const clearRoute = (routes) => {
        routes.forEach((item) => {
            if (item.childList && item.childList.length > 0) {
                clearRoute(item.childList)
            } else {
                router.hasRoute(item.pathName) && router.removeRoute(item.pathName)
            }
        })
    }
    // 加载路由
export const createDynamicRoutes = (dynamicRoutes) => {
    clearRoute(dynamicRoutes)
    if (dynamicRoutes.length) {
        loadRoute(dynamicRoutes)
    }
}

const getInfos = async() => {
        const userInfo_store = userInfoStore()
        const res = await UserMangerApi.getUserInfo('PC') || {}
            // 设置系统语言
        res.language && (i18n.global.locale.value = res.language)

        userInfo_store.setUserInfo(res)
        const router_store = routerAndPremission()
        await router_store.getRouterAndPremission(res.roleId)

        const team_store = teamStore()
        team_store.setTeamId(res.teamId)
        team_store.setTeamName(res.teamName)
    }
    // 全局前置守卫
let firstFlag = false
router.beforeEach((to, from, next) => {
    // 进入邀请页面
    if (to.path === '/invite') {
        const token = TokenStorage.get()
        if (token) {
            next()
        } else {
            next({ path: '/login', query: to.query })
        }
        firstFlag = false
        return
    }
    // 登录后才能相关功能页
    const router_store = routerAndPremission()
    const userInfo_store = userInfoStore()
    if (to.path.includes('/login')) { // 登录页面
        router_store.resetRouterAndPermission(true) // 重置路由 不包含许可中心
        sessionStorage.clear()
        userInfo_store.setUserInfo({}) // 只有登陆页面重置用户信息  许可页面头部需要用户信息
        firstFlag = false
        next()
    } else if (to.path.includes('/license')) { // 许可页面
        router_store.resetRouterAndPermission(false) // 重置路由 包含许可中心
        sessionStorage.clear()
        firstFlag = false
        next()
    } else {
        const token = TokenStorage.get()
        if (!token) {
            next({ path: '/login' })
        } else {
            if (!firstFlag) {
                getInfos().then(() => {
                    const router_store = routerAndPremission()
                    const currentRoute = window.location.pathname
                    if (currentRoute !== '/') {
                        if (currentRoute === '/login' || currentRoute === '/invite' || currentRoute === '/license') {
                            next({ path: router_store.router[0].menuPath })
                        } else {
                            const search = window.location.search
                            const query = {}
                            if (search) {
                                const splitsFirst = search.split('?')
                                const splitsSecond = splitsFirst[1].split('&')
                                splitsSecond.forEach(item => {
                                    const splitFinal = item.split('=')
                                    query[splitFinal[0]] = splitFinal[1]
                                })
                            }
                            next({ path: currentRoute, query })
                        }
                    } else {
                        next({ path: router_store.router[0].menuPath })
                    }

                    firstFlag = true
                })
            } else {
                next()
            }
        }
    }
})
export default router
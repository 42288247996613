/*
 * @Author: seelingzheng
 * @Date: 2024-06-27 10:29:36
 * @LastEditors: seelingzheng
 * @LastEditTime: 2024-06-27 10:31:51
 * @Description: 授权中文页面
 */
export default {
  title: '获取示例项目权限及软件激活',
  subTitle: '获取示例项目权限和软件激活，请联系你所在地区的销售经理',
  // 区域
  china: '中国'
}

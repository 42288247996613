export default {
  imagePoint: 'ImagePoint',
  inspectionPoint: 'InspectionPoint',
  diseasePoint: 'Disease',
  perspective: 'Perspective',
  diseaseDisplay: 'Disease display',
  point:'Point',
  line:'Shape',
  shapeTips: 'When switching disease to [shape] display, performance loss increases'
}

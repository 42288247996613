export default {
  // 病害类型
  CRACK: '裂缝',
  DISEASE_SPALLING: '剥落',
  DISEASE_RUST: '锈蚀',
  DISEASE_DEFORM: '变形',
  DISEASE_HOLE: '空洞/孔洞',
  DISEASE_WORN: '破损',
  DISEASE_REVEAL_REIN: '露筋',
  DISEASE_BURN_IN: '老化',
  DISEASE_SHEATH_DAMAGE: '护套破损/脱落',
  DISEASE_PEELPAINT: '掉漆/面漆起皮',
  DISEASE_BULGING: '鼓肚',
  DISEASE_CARBONIZATION: '混泥土碳化',
  DISEASE_WATER_SEEPAGE: '渗水',
  DISEASE_WEBLIKE_CRACK: '网状裂缝',
  DISEASE_HONEYCOMB: '蜂窝麻面',
  DISEASE_SCRATCH_DAMAGE:'刮损',
  DISEASE_SCRATCH:'刮痕',

  // 病害字段名
  FIELD_Index: '编号',
  FIELD_no: '病害编号',
  FIELD_type: '病害类型',
  FIELD_size: '病害尺寸',
  FIELD_desc: '病害描述',
  FIELD_ImageIndex: '图片编号',
  FIELD_mark: '空间标记',
  FIELD_scale: '病害标度'
}

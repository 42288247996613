/*
 * @Author: zhengqj
 * @Date: 2024-06-21 10:41:47
 * @LastEditors: zhengqj
 * @LastEditTime: 2024-06-21 13:25:28
 * @Description: API 请求 国际化-zh_CN
 */
export default {
  // http 请求
  cookie: '登陆失效，请重新登陆',
  serviceError: '服务器异常，请联系管理员',
  offLine: '网络异常，请检查网络',
  timeout: '请求超时，请重试',
  notFound: '请求地址不存在，请联系管理员',

  INSPECTION_IN_AI_CAN_NOT_OPERA: 'AI正在筛查中，请稍后操作',
  // 用户
  USER_PHONE_EXISTING: '用户手机号重复',
  // 系统 2000,2003,5000,4003
  // 项目相关
  PROJECT_ERROR: '创建项目错误',
  PROJECT_NO_ERROR: '项目不存在',
  PROJECT_TYPE_CODE_ERROR: '项目类型重复',
  PROJECT_MODEL_USER: '模型已有其用户在上传',
  PROJECT_NAME_EXITS_ERROR: '项目名称已存在',
  PROJECT_TYPE_NAME_ERROR: '项目类型名称重复',
  DISEASE_TYPE_UPDATE_ERROR: '系统内置病害类型不能修改',
  PROJECT_MODEL_VERSION_ERROR:'上传失败，请刷新页面后重试',   
  PROJECT_MODEL_MD_ERROR:'上传失败，请刷新页面后重试',
  PROJECT_MODEL_UPLOAD_ERROR:'上传失败，请刷新页面后重试',
  // 巡检管理
  REPORT_ERROR: '报告生成错误',
  TEMPLATE_FILE_ERROR: '模板文件错误',
  INSPECTION_IMAGE_NOT_EXISTING: '巡检照片不存在',
  INSPECTION_NO_ERROR: '巡检不存在',
  INSPECTION_DISEASE_NOT_EXISTING: '巡检病害不存在',
  INSPECTION_NODE_NOT_EXISTING: '巡检点不存在',
  REPORT_UPDATE_ERROR: '报告生成中，请稍后重试',
  IMAGES_GROUP_NAME_ERROR: '图片分组名称重复',
  // 巡检点
  INSPECTION_NODE_REPEAT: '已存在经纬度、高度完全一致的巡检点',
  // 病害管理
  EXCEL_ERROR: '生成excel错误',
  NO_DISEASE_ERROR: '病害不存在',
  INSPECTION_DISEASE_TYPE_NOT_EXISTING: '巡检病害类型不存在',
  DISEASE_EXPORT_ERROR: '病害数超过上限导出失败',
  DISEASE_TYPE_NAME_ERROR: '病害类型名称重复',
  DISEASE_TYPE_BING_DISEASE: '病害类型已被使用，删除失败',
  BIND_DISEASE_NO_UPDATE: '病害类型已被使用，不能切换显示状态',
  // 团队管理
  USER_NO_TEAM_ERROR: '用户没有绑定该团队',
  TEAM_USER_IN_TEAM: '您已经是该团队的一员了，请勿重复加入',
  TEAM_USER_COUNT_UPPER_LIMIT: '团队人数已满，请联系你的管理员',
  TEAM_INVITATION_CODE_INVALID: '链接校验码错误，还有{checkCount}次机会',
  TEAM_INVITATION_LINK_INVALID: '链接不存在或已失效',
  TEAM_USER_JOIN_COUNT_LIMIT: '用户加入团队密码错误5次以上，请稍1小时后再试',
  TEAM_OWNER_CAN_NOT_REMOVE: '团队拥有者无法被删除',
  TEAM_OPERA_INCONSISTENT: '用户正在操作的对象所属团队与用户登录团队不一致',
  TEAM_VOLUME_EXCEED: '团队储存容量已上限，请进行升级',
  PROJECT_CUSTOM_NAME_ERROR: '病害名称重复',
  CUSTOM_TYPE_ERROR: '已绑定病害，不能进行修改',
  CUSTOM_INIT_ERROR: '已绑定病害，不能修改选项',
  // 通知
  NOTICE_SEND_FAIL: '消息推送失败',
  JSON_ERROR: 'SSE消息推送失败',

  // 鉴权管理
  // code 4001
  NO_AUTH: '没有操作权限',
  ROLE_CHANGE_LIMIT: '角色调整限制',
  // 系统管理
  INVALID_CODE_ERROR: '错误的验证码',
  TEAM_RIEBO_NOT_EXISTING: 'RIEBO团队不存在',

  // 登录、登出
  USER_SMS_CODE_CHECK_FAIL: '手机验证码校验失败',
  USER_PHONE_NOT_EXISTING: '手机号不存在',
  USER_OR_PASSWORD_ERROR: '用户名或密码错误',
  USER_REGISTER_FAIL: '注册失败',
  USER_PASSWORD_CHECK: '密码校验错误',
  LOGIN_FAILURE: '登录已过期',
  SEND_SMS_FAIL_HOUR: '超出短信发送次数上限，请稍后再试',
  SEND_SMS_FAIL_ERROR: '发送短信失败',
  MOBILE_NUMBER_ILLEGAL: '手机号格式错误',
  AREA_ERROR: '区域错误',
  LOGIN_CLIENT_ERROR: '用户登录客户端不合法',
  LOGIN_REPEATED: '此账号已有用户登录',
  SEND_SMS_FAIL_MIN: '短信发送次数到达上限，请一分钟后再试',
  SEND_SMS_FAIL_DAY: '短信发送次数到达上限，请一天后再试',
  ABNORMAL_OPERATION: '非法操作',
  USER_ACCOUNT_ABNORMALITY: '用户已禁用',
  // 文件操作
  FILE_NO_CONTENT: '未解析到文件内容',
  FILE_SAVE_FAIL: '文件保存失败',
  FILE_PARSE_FAIL: '文件解析失败',

  // 空间点
  PARENT_TREE_PARAMETER: '空间点传入参数异常',
  PARENT_TREE_ID_NOT_ERROR: '空间节点参数异常',
  PARAMETER_ERROR: '空间节点参数异常',
  PARENT_BIND_NODE: '空间节点绑定的父空间节点，绑定了该子节点',
  PARAMETER_PROBLEM: '参数异常',
  SPACE_MARKING_NAME_ERROR: '空间标注名称重复',

  // AI
  START_AI_TYPE_ERROR: 'AI筛查开始类型错误',
  CURRENT_INSPECTION_NO_IMAGE: '当前巡检暂无可AI筛查的照片',
  AI_SERVER_EXCEPTION: 'AI服务异常',
  START_AI_CHECK_FAIL: '开始AI筛查失败',
  AI_CHECK_QUEUE_POSITION_FAIL: 'AI筛查队列位置异常',
  AI_CHECK_QUEUE_JUMP_FAIL: 'AI筛查插队异常',
  AI_CHECK_CANCEL_FAIL: '取消AI筛查失败',
  CURRENT_INSPECTION_NO_AI_IMAGE: '当前巡检暂无可AI筛查的照片',
  
  NO_INSPECTION_FOR_THE_IMAGE:"未查询到图片所属的巡检信息",
  PROJECT_MODEL_ERROR_FOR_THE_IMAGE:"未查询到图片所属项目模型",
  IMAGE_ERROR_FOR_THE_DISEASE:"未查询到病害的图片信息或信息不完整",
  IMAGE_PX_LENGTH_CALCULATION_ERROR:"图片像素长度计算错误",
  DISEASE_TYPE_NOT_COVER_AI:"病害类型未覆盖AI筛查结果类型",
  DISEASE_IMAGE_URL_NULL:"病害图片的URL为空",
  AI_RESULT_SAVE_ERROR:"AI结果保存异常",
  TOW_D_TO_3D_FAIL:"二维坐标转三维坐标异常",
  AI_SERVER_CHECK_ERROR:"AI服务校验异常",
  CURRENT_INSPECTION_NO_AI_DISEASE_TYPE:"当前巡检暂无AI病害类型",
  AI_SERVER_RECOGNITION_TIME_OUT:"AI服务识别超时",
  AI_SERVER_DOWN_IMG_URL_NOT_EXIST:"AI服务下载图片url不存在",
  AI_SERVER_IMG_DECRYPT_FAIL:"AI服务图片解密失败",
  FEEDBACK_AI_RESULT_PARAM_EMPTY:"AI筛查结果反馈必要参数为空",
  FEEDBACK_AI_RESULT_NOT_EXISTING:"AI筛查信息不存在,关联数据可能已被删除",
  GROUP_DOWN_IMAGE_ERROR:"删除图片分组下还有图片未删除",
  IMAGES_GROUP_NAME_ERROR:"图片分组名重复",
  IMAGES_DRAW_ERROR:'图片绘制失败'

}


export default {
  imagePoint: '图片点',
  inspectionPoint: '巡检点',
  diseasePoint: '病害',
  perspective: '透视',
  diseaseDisplay: '病害显示模式',
  point:'点',
  line:'形状',
  shapeTips: '切换病害为[形状]展示时,性能损耗加大'
}
